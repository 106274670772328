import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { serverURL } from "../constants";
const SuccessPage = () => {
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      try {
        const email = sessionStorage.getItem('email');
        const response = await fetch(`${serverURL}/api/transactions?email=${email}`);

        if (response.ok) {
          const data = await response.json();
          setSubscriptionDetails(data[0]); // Assuming there's only one transaction per email
        } else {
          console.error('Error fetching subscription details');
        }
      } catch (error) {
        console.error('Error fetching subscription details:', error);
      }
    };

    fetchSubscriptionDetails();
  }, []);

  const handleBackToHomepage = () => {
    navigate('/home');
  };

  if (!subscriptionDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 p-4 md:p-8 flex flex-col items-center justify-center">
      <div className="w-full max-w-md space-y-8">
        <div className="text-center space-y-4">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900">Subscription Confirmed</h1>
          <p className="text-xl text-gray-600">Thank you for subscribing!</p>
        </div>

        <div className="bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl transform hover:-translate-y-1">
          <div className="p-6">
            <h2 className="text-2xl font-bold text-gray-900">Your Subscription Details</h2>
            <p className="text-gray-600 mt-2">
              Subscription Type: {subscriptionDetails.duration}
            </p>
            <p className="text-gray-600 mt-2">
              Subscription Start: {new Date(subscriptionDetails.subscriptionStart).toLocaleDateString()}
            </p>
            <p className="text-gray-600 mt-2">
              Subscription End: {new Date(subscriptionDetails.subscriptionEnd).toLocaleDateString()}
            </p>
          </div>
          <div className="p-6 bg-gray-50">
            <button
              onClick={handleBackToHomepage}
              className="w-full py-2 px-4 rounded-full transition-colors duration-200 ease-in-out bg-blue-500 text-white hover:bg-blue-600 flex items-center justify-center"
            >
              Back to Homepage
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;