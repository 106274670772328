import React from 'react';
import PricingComponent from '../components/ngpayment';

function NgPricingPage() {
  return (
    <div>
      <PricingComponent />
    </div>
  );
}

export default NgPricingPage;