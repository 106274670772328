import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/header';
import Footers from '../components/footers';
import SlideOne from '../components/landing/slideOne';
import LiveChat from '../components/landing/LiveChat';
import { serverURL, name, logo, websiteURL } from '../constants';
import axios from 'axios';
import Clarity from '../components/Clarity';

const Landing = () => {

    useEffect(() => {
        async function dashboardData() {
            const postURL = serverURL + `/api/policies`;
            const response = await axios.get(postURL);
            sessionStorage.setItem('TermsPolicy', response.data[0].terms);
            sessionStorage.setItem('PrivacyPolicy', response.data[0].privacy);
        }
        if (
            sessionStorage.getItem('TermsPolicy') === null &&
            sessionStorage.getItem('PrivacyPolicy') === null
        ) {
            dashboardData();
        }
    }, []);

    return (
        <>

            <Helmet>
                <title>{name}</title>
                <meta
                    name="description"
                    content={`${name} is your AI-powered learning platform.`}
                />
                <meta
                    name="keywords"
                    content={`${name}, AI Teacher, Personalized Learning`}
                />
                <meta property="og:title" content={name} />
                <meta
                    property="og:description"
                    content={`${name} is your AI-powered learning platform.`}
                />
                <meta property="og:url" content={`${websiteURL}`} />
                <meta property="og:type" content="website" />
                <meta property="og:image" content={logo} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={name} />
                <meta
                    name="twitter:description"
                    content={`${name} is your AI-powered learning platform.`}
                />
                <meta name="twitter:image" content={logo} />
            </Helmet>

            <Header isHome={false} />
            <SlideOne />

            <Footers />
            <LiveChat />
            <Clarity />
        </>
    );
};

export default Landing;