import { Bar, BarChart, CartesianGrid, XAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { useEffect, useState } from "react";
import axios from "axios";
import { serverURL } from "../constants";

const defaultChartData = [
  { day: "Sun", signQuiz: 0, signTeacher: 0, quizDo: 0, courseDo: 0 },
  { day: "Mon", signQuiz: 0, signTeacher: 0, quizDo: 0, courseDo: 0 },
  { day: "Tue", signQuiz: 0, signTeacher: 0, quizDo: 0, courseDo: 0 },
  { day: "Wed", signQuiz: 0, signTeacher: 0, quizDo: 0, courseDo: 0 },
  { day: "Thu", signQuiz: 0, signTeacher: 0, quizDo: 0, courseDo: 0 },
  { day: "Fri", signQuiz: 0, signTeacher: 0, quizDo: 0, courseDo: 0 },
  { day: "Sat", signQuiz: 0, signTeacher: 0, quizDo: 0, courseDo: 0 },
];

const chartConfig = {
  signQuiz: { label: "LoginQuiz", color: "#ff6347" },
  signTeacher: { label: "LoginAiTeacher", color: "#4682b4" },
  quizDo: { label: "CreatedQuiz", color: "#3cb371" },
  courseDo: { label: "CreatedCourse", color: "#ffb6c1" },
};

export function BarChartStreak() {
  const [chartData, setChartData] = useState(defaultChartData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchUserStreak() {
      try {
        setLoading(true);
        const res = await axios.post(`${serverURL}/api/getStreakPerUser`, {
          email: sessionStorage.getItem("email"),
          app: "AITeacher"
        });

        if (res.data.status === 200 && res.data.chartData) {
          const transformedData = Object.keys(res.data.chartData).map(
            (day) => ({
              day,
              ...res.data.chartData[day],
            })
          );
          setChartData(transformedData);
        } else {
          console.error("Failed to fetch streak data:", res.data.message);
          setError("Failed to fetch streak data");
          setChartData(defaultChartData);
        }
      } catch (error) {
        console.error("Error fetching streak data:", error);
        setError("Error fetching streak data");
        setChartData(defaultChartData);
      } finally {
        setLoading(false);
      }
    }

    fetchUserStreak();
  }, []);

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  return (
    <div className="border border-gray-300 dark:border-gray-700 rounded-lg p-4 bg-white dark:bg-gray-800">
      <div className="text-black dark:text-white mb-4">
        <h3 className="text-lg font-semibold">Streak During Last Week</h3>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid vertical={false} stroke="#ccc" />
          <XAxis
            dataKey="day"
            tickLine={false}
            tickMargin={10}
            axisLine={false}
            tickFormatter={(value) => value}
          />
          <Tooltip />
          <Legend />
          {Object.keys(chartConfig).map((key) => (
            <Bar
              key={key}
              dataKey={key}
              name={chartConfig[key].label}
              stackId="a"
              fill={chartConfig[key].color}
              radius={[4, 4, 0, 0]}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}