import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Label,
  Radio,
  Select,
  TextInput,
  Card,
  Spinner,
} from "flowbite-react";
import {
  HiPlus,
  HiMinus,
  HiAcademicCap,
  HiLightBulb,
  HiGlobe,
  HiBookOpen,
} from "react-icons/hi";
import { motion } from "framer-motion";
import Header from "../components/header";
import Footers from "../components/footers";
import {
  serverURL,
  languages,
  level,
  countries,
  universities,
  curriculums,
} from "../constants";

const Create = () => {
  const maxSubtopics = 5;
  const [formValues, setFormValues] = useState([{ sub: "" }]);
  const [processing, setProcessing] = useState(false);
  const [selectedValue, setSelectedValue] = useState("4");
  const [selectedType, setSelectedType] = useState("Text & Image Course");
  const [paidMember, setPaidMember] = useState(false);
  const [lableText, setLableText] = useState(
    "For free member sub topics is limited to 5"
  );
  const [selectedCurriculum, setSelectedCurriculum] = useState("");
  const [isUniversitySelected, setIsUniversitySelected] = useState(false);
  const [selectedUni, setSelectedUni] = useState("");
  const [selectedRegions, setSelectedRegions] = useState("Afghanistan");
  const [language, setLanguage] = useState("English");
  const [selectedLevel, setSelectedLevel] = useState("easy");
  const [valid, setValid] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const isVerified = sessionStorage.getItem("isVerified");
    if (!isVerified) {
      navigate("/home");
    }
  }, [navigate]);

  useEffect(() => {
    if (sessionStorage.getItem("type") !== "free") {
      setPaidMember(true);
      setLableText("Select number of sub topics");
    } else {
      let validCheck = async (uid) => {
        const dataToSend = {
          user: uid,
        };
        const postURL = serverURL + "/api/valid";
        const res = await axios.post(postURL, dataToSend);
        if (res.data.message !== "valid") {
          setValid(false);
        }
      };
      validCheck(sessionStorage.getItem("uid"));
    }
  }, [setValid, navigate]);

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    if (formValues.length < maxSubtopics) {
      setFormValues([...formValues, { sub: "" }]);
    } else {
      showToast("You can only add 5 sub topics");
    }
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const showPaidToast = () => {
    if (!paidMember) {
      toast("For paid members only", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const showToast = (msg) => {
    toast(msg, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const subtopics = [];
    setProcessing(true);
    formValues.forEach((subtopic) => {
      subtopics.push(subtopic.sub);
    });

    const mainTopic = document.getElementById("topic1").value;

    if (!mainTopic.trim()) {
      setProcessing(false);
      showToast("Please fill in all required fields");
      return;
    }

    if (subtopics.length === 0) {
      setProcessing(false);
      showToast("Please fill in all required fields");
      return;
    }
    const prompt = `Generate a list of Strict ${selectedValue} topics based on the ${selectedLevel} or if the user has a ${selectedUni} and should strictly get the information from ${selectedUni} websites  and any number sub topic for each topic for main title ${mainTopic.toLowerCase()}, everything in single line. Those topics should be in ${language} language,Those ${selectedValue} topics should Strictly include these topics :- ${subtopics
      .join(", ")
      .toLowerCase()}. Strictly Keep theory, youtube, image field empty. Generate in the form of JSON in this format {
            "${mainTopic.toLowerCase()}": [
       {
       "title": "Topic Title",
       "subtopics": [
        {
        "title": "Sub Topic Title",
        "theory": "",
        "youtube": "",
        "image": "",
        "done": false
        },
        {
        "title": "Sub Topic Title",
        "theory": "",
        "youtube": "",
        "image": "",
        "done": false
        }
       ]
       },
       {
       "title": "Topic Title",
       "subtopics": [
        {
        "title": "Sub Topic Title",
        "theory": "",
        "youtube": "",
        "image": "",
        "done": false
        },
        {
        "title": "Sub Topic Title",
        "theory": "",
        "youtube": "",
        "image": "",
        "done": false
        }
       ]
       }
      ]
      }`;

    sendPrompt(
      prompt,
      mainTopic,
      selectedType,
      selectedUni,
      selectedRegions,
      selectedCurriculum
    );
  };

  async function sendPrompt(prompt, mainTopic, selectedType) {
    const dataToSend = {
      prompt: prompt,
    };
    try {
      const postURL = serverURL + "/api/prompt";
      const res = await axios.post(postURL, dataToSend);
      const generatedText = res.data.generatedText;
      const cleanedJsonString = generatedText
        .replace(/```json/g, "")
        .replace(/```/g, "");
      try {
        const parsedJson = JSON.parse(cleanedJsonString);
        setProcessing(false);
        navigate("/topics", {
          state: {
            jsonData: parsedJson,
            mainTopic: mainTopic.toLowerCase(),
            type: selectedType.toLowerCase(),
            language: language,
          },
        });
      } catch (error) {
        sendPrompt(prompt, mainTopic, selectedType);
      }
    } catch (error) {
      sendPrompt(prompt, mainTopic, selectedType);
    }
  }

  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const slideIn = {
    hidden: { x: -50, opacity: 0 },
    visible: { x: 0, opacity: 1 },
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-r from-blue-500 to-blue-700 text-white">
    <Header isHome={true} className="sticky top-0 z-50" />

      <motion.div
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        transition={{ duration: 0.5 }}
        className="flex-1 flex items-center justify-center py-10 px-4"
      >
        <Card className="w-full max-w-4xl bg-white rounded-lg shadow-lg p-8">
          <div className="space-y-8">
            <motion.div variants={slideIn} className="text-center space-y-2">
              <h2 className="text-4xl font-extrabold text-blue-900">
                Learn Anything You Have Difficulty Grasping with Ai
              </h2>
              <p className="text-gray-600 text-lg">
                Just Tell us what Subjects you are having Difficulty in and
                leave the rest for us
              </p>
            </motion.div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <motion.div variants={slideIn} className="space-y-4">
                <div>
                  <Label
                    htmlFor="topic1"
                    value="Main Topic"
                    className="text-lg font-semibold text-gray-700"
                  />
                  <TextInput
                    id="topic1"
                    type="text"
                    placeholder="What do you want to learn?"
                    required
                    icon={HiLightBulb}
                    className="text-black"
                  />
                </div>

                <div>
                  <Label
                    htmlFor="subtopic"
                    value="Subtopics"
                    className="text-lg font-semibold text-gray-700"
                  />
                  {formValues.map((element, index) => (
                    <motion.div
                      key={index}
                      variants={fadeIn}
                      className="flex items-center space-x-2 mb-2"
                    >
                      <TextInput
                        id={`subtopic-${index}`}
                        name="sub"
                        type="text"
                        placeholder={`Subtopic ${index + 1}`}
                        value={element.sub}
                        onChange={(e) => handleChange(index, e)}
                        className="flex-grow text-black"
                      />
                      {index > 0 && (
                        <Button
                          color="failure"
                          size="sm"
                          onClick={() => removeFormFields(index)}
                        >
                          <HiMinus className="h-4 w-4" />
                        </Button>
                      )}
                    </motion.div>
                  ))}
                  {formValues.length < maxSubtopics && (
                    <Button
                      type="button"
                      onClick={addFormFields}
                      className="mt-2"
                    >
                      <HiPlus className="mr-2 h-4 w-4" /> Add Subtopic
                    </Button>
                  )}
                </div>
              </motion.div>

              <motion.div variants={slideIn} className="space-y-4">
                <div>
                  <Label
                    value={lableText}
                    className="text-lg font-semibold text-gray-700"
                  />
                  <div className="flex flex-col gap-2 mt-2">
                    <div className="flex items-center">
                      <Radio
                        id="4"
                        name="value"
                        value="4"
                        checked={selectedValue === "4"}
                        onChange={() => setSelectedValue("4")}
                      />
                      <Label htmlFor="4" className="ml-2 text-gray-700">
                        5 Topics
                      </Label>
                    </div>
                    <div className="flex items-center" onClick={showPaidToast}>
                      <Radio
                        id="7"
                        name="value"
                        value="7"
                        checked={selectedValue === "7"}
                        onChange={() => setSelectedValue("7")}
                        disabled={!paidMember}
                      />
                      <Label htmlFor="7" className="ml-2 text-gray-700">
                        10 Topics
                      </Label>
                    </div>
                  </div>
                </div>

                <div>
                  <Label
                    value="Course Type"
                    className="text-lg font-semibold text-gray-700"
                  />
                  <div className="flex flex-col gap-2 mt-2">
                    <div className="flex items-center">
                      <Radio
                        id="textcourse"
                        name="value1"
                        value="Text & Image Course"
                        checked={selectedType === "Text & Image Course"}
                        onChange={() => setSelectedType("Text & Image Course")}
                      />
                      <Label
                        htmlFor="textcourse"
                        className="ml-2 text-gray-700"
                      >
                        Text & Image Course
                      </Label>
                    </div>
                    <div className="flex items-center" onClick={showPaidToast}>
                      <Radio
                        id="videocourse"
                        name="value1"
                        value="Video & Text Course"
                        checked={selectedType === "Video & Text Course"}
                        onChange={() => setSelectedType("Video & Text Course")}
                        disabled={!paidMember}
                      />
                      <Label
                        htmlFor="videocourse"
                        className="ml-2 text-gray-700"
                      >
                        Video, Text & PDF Course
                      </Label>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>

            <motion.div
              variants={slideIn}
              className="grid grid-cols-1 md:grid-cols-3 gap-6"
            >
              <div onClick={showPaidToast}>
                <Label
                  htmlFor="lang"
                  value="Language"
                  className="text-lg font-semibold text-gray-700"
                />
                <Select
                  id="lang"
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                  disabled={!paidMember}
                  icon={HiGlobe}
                  className="text-black"
                >
                  {languages.map((lang) => (
                    <option key={lang.value} value={lang.value}>
                      {lang.label}
                    </option>
                  ))}
                </Select>
                
              </div>

              <div onClick={showPaidToast}>
                <Label
                  htmlFor="level"
                  value="Education Level"
                  className="text-lg font-semibold text-gray-700"
                />
                <Select
                  id="level"
                  value={selectedLevel}
                  onChange={(e) => {
                    setSelectedLevel(e.target.value);
                    setIsUniversitySelected(
                      e.target.value === "University College"
                    );
                  }}
                  disabled={!paidMember}
                  icon={HiAcademicCap}
                  className="text-black"
                >
                  {level.map((lvl) => (
                    <option key={lvl.value} value={lvl.value}>
                      {lvl.label}
                    </option>
                  ))}
                </Select>
              </div>

              <div onClick={showPaidToast}>
                <Label
                  htmlFor="countries"
                  value="Country"
                  className="text-lg font-semibold text-gray-700"
                />
                <Select
                  id="countries"
                  value={selectedRegions}
                  onChange={(e) => setSelectedRegions(e.target.value)}
                  icon={HiGlobe}
                  className="text-black"
                >
                  {countries.map((con) => (
                    <option key={con.value} value={con.value}>
                      {con.label}
                    </option>
                  ))}
                </Select>
              </div>
            </motion.div>

            <motion.div
              variants={slideIn}
              className="grid grid-cols-1 md:grid-cols-2 gap-6"
            >
              <div onClick={showPaidToast}>
                <Label
                  htmlFor="universities"
                  value="University"
                  className="text-lg font-semibold text-gray-700"
                />
                <Select
                  id="universities"
                  value={selectedUni}
                  onChange={(e) => setSelectedUni(e.target.value)}
                  disabled={!isUniversitySelected || !selectedRegions}
                  icon={HiAcademicCap}
                  className="text-black"
                >
                  {universities[selectedRegions]?.map((uni) => (
                    <option key={uni.value} value={uni.value}>
                      {uni.label}
                    </option>
                  )) || <option value="">Please select a country first</option>}
                </Select>
              </div>

              <div onClick={showPaidToast}>
                <Label
                  htmlFor="curriculums"
                  value="Curriculum"
                  className="text-lg font-semibold text-gray-700"
                />
                <Select
                  id="curriculums"
                  value={selectedCurriculum}
                  onChange={(e) => setSelectedCurriculum(e.target.value)}
                  disabled={!isUniversitySelected}
                  icon={HiBookOpen}
                  className="text-black"
                >
                  {curriculums.map((cur) => (
                    <option key={cur.value} value={cur.value}>
                      {cur.label}
                    </option>
                  ))}
                </Select>
              </div>
            </motion.div>

            <motion.div variants={fadeIn} className="flex justify-center">
  <Button
    onClick={valid && handleSubmit}
    size="xl"
    className="w-full md:w-auto px-8 py-3 bg-blue-600 text-white hover:bg-blue-700"
    disabled={processing}
  >
    {processing ? (
      <>
        <Spinner size="sm" light={true} />
        <span className="ml-2">Creating Your Course...</span>
      </>
    ) : (
      <>
        <HiAcademicCap className="mr-2 h-6 w-6" />
        Start Your Learning Journey
      </>
    )}
  </Button>
</motion.div>
          </div>
        </Card>
      </motion.div>

      <Footers className="mt-auto bg-gradient-to-r from-blue-500 to-blue-700 text-white" />
      <ToastContainer />
    </div>
  );
};

export default Create;
