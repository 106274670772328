import React, { useEffect, useState, useCallback } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import axios from "axios";
import { Button } from "flowbite-react";
import 'flowbite/dist/flowbite.min.css';

// Custom hook for speech synthesis
function useSpeechSynthesis() {
  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState(null);

  useEffect(() => {
    function loadVoices() {
      const availableVoices = window.speechSynthesis.getVoices();
      setVoices(availableVoices);
      
      // Try to find a female voice, otherwise use the first available voice
      const femaleVoice = availableVoices.find(voice => voice.name.includes('Female') || voice.name.includes('female'));
      setSelectedVoice(femaleVoice || availableVoices[0]);
    }

    loadVoices();
    if (window.speechSynthesis.onvoiceschanged !== undefined) {
      window.speechSynthesis.onvoiceschanged = loadVoices;
    }
  }, []);

  const speak = useCallback((text) => {
    if (!selectedVoice) return;

    const utterance = new SpeechSynthesisUtterance(text);
    utterance.voice = selectedVoice;
    utterance.rate = 0.9; // Slightly slower rate for better comprehension
    utterance.pitch = 1;
    window.speechSynthesis.speak(utterance);
  }, [selectedVoice]);

  const stopSpeaking = () => {
    window.speechSynthesis.cancel();
  };

  return { speak, stopSpeaking, voices, selectedVoice, setSelectedVoice };
}

const AIVoiceTeacher = () => {
  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
  const { speak, stopSpeaking, voices, selectedVoice, setSelectedVoice } = useSpeechSynthesis();
  const [followUpQuestions, setFollowUpQuestions] = useState([]);
  const [currentTopic, setCurrentTopic] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [awaitingResponse, setAwaitingResponse] = useState(false);
  const [finalResponse, setFinalResponse] = useState("");
  const [waitingForUserResponse, setWaitingForUserResponse] = useState(false);

  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const generateFollowUpQuestions = useCallback(async (transcript) => {
    try {
      const prompt = `
      You are an advanced AI teaching assistant specialized in helping visually impaired students. Based on the following input and the current topic "${currentTopic}", generate three follow-up questions to deepen the student's understanding:
      
      Student's input: "${transcript}"
      
      Ensure the questions are clear, concise, and progressively build on the topic. Avoid visual references.
      `;

      const response = await axios.post('https://aiteacher.learnrithm.com/api/chat', { prompt });
      const aiResponse = response.data.text || "I apologize, but I couldn't generate follow-up questions. Could you please rephrase your question?";
      const cleanResponse = stripHtmlTags(aiResponse);
      console.log("Generated follow-up questions:", cleanResponse);
      const questions = cleanResponse.split('\n').filter(q => q.trim() !== "");
      setFollowUpQuestions(questions);
      setAwaitingResponse(true); // Set awaiting response to true after generating follow-up questions
    } catch (error) {
      console.error('Error:', error);
      speak('I apologize, but I encountered an error while generating follow-up questions. Could you please try asking your question again?');
    }
  }, [speak, currentTopic]);

  const processTranscript = useCallback(async (transcript) => {
    setIsProcessing(true);
    try {
      const prompt = `
      You are an advanced AI teaching assistant specialized in helping visually impaired students. Please provide a detailed and clear audio-friendly response to the following input, considering the current topic "${currentTopic}":
      
      Student's question: "${transcript}"
      
      Your response should:
      1. Be easy to understand when spoken aloud
      2. Avoid visual references or descriptions
      3. Use analogies and examples that don't rely on sight
      4. Break down complex concepts into simple, sequential explanations
      5. Encourage critical thinking and further exploration of the topic
      
      If the student's question is unclear or off-topic, kindly ask for clarification or gently guide them back to the current topic.
      `;

      const response = await axios.post('https://aiteacher.learnrithm.com/api/chat', { prompt });
      const aiResponse = response.data.text || "I apologize, but I couldn't generate a response. Could you please rephrase your question?";
      const cleanResponse = stripHtmlTags(aiResponse);
      console.log("AI response:", cleanResponse);
      setFinalResponse(cleanResponse);
      setCurrentTopic(transcript); // Update the current topic based on the student's question
    } catch (error) {
      console.error('Error:', error);
      speak('I apologize, but I encountered an error while processing your request. Could you please try asking your question again?');
    } finally {
      setIsProcessing(false);
    }
  }, [speak, currentTopic]);

  const askFollowUpQuestion = useCallback((question) => {
    speak(question);
    setWaitingForUserResponse(true); // Wait for user's response
  }, [speak]);

  useEffect(() => {
    if (!listening && transcript && !isProcessing && awaitingResponse && waitingForUserResponse) {
      console.log("User's response to follow-up question:", transcript);
      setWaitingForUserResponse(false);
      // Optionally process the user's response to the follow-up question here

      if (followUpQuestions.length > 0) {
        const nextQuestion = followUpQuestions.shift();
        setFollowUpQuestions([...followUpQuestions]);
        askFollowUpQuestion(nextQuestion);
      } else {
        processTranscript(transcript);
        setAwaitingResponse(false); // Reset awaiting response after processing the final transcript
      }
    }
  }, [listening, transcript, processTranscript, followUpQuestions, askFollowUpQuestion, isProcessing, awaitingResponse, waitingForUserResponse]);

  useEffect(() => {
    if (!listening && !isProcessing && !awaitingResponse && !waitingForUserResponse && transcript) {
      generateFollowUpQuestions(transcript);
    }
  }, [transcript, listening, generateFollowUpQuestions, isProcessing, awaitingResponse, waitingForUserResponse]);

  useEffect(() => {
    if (finalResponse) {
      speak(finalResponse);
      setFinalResponse("");
    }
  }, [finalResponse, speak]);

  if (!browserSupportsSpeechRecognition) {
    return <div>Your browser does not support speech recognition. Please try using a different browser or device.</div>;
  }

  const handleStartListening = () => {
    resetTranscript();
    SpeechRecognition.startListening({ continuous: true });
    if (!awaitingResponse && !waitingForUserResponse) {
      speak("Hello! I'm your AI teaching assistant. What would you like to learn about today?");
    } else if (waitingForUserResponse) {
      speak("Please provide your response to the question.");
    }
    console.log("Listening started");
  };

  const handleStopListening = () => {
    SpeechRecognition.stopListening();
    if (!awaitingResponse && !waitingForUserResponse) {
      speak("Thank you for your question. I'll process that and respond shortly.");
    } else if (waitingForUserResponse) {
      speak("Thank you for your response.");
    }
    console.log("Listening stopped");
  };

  return (
    <div className="p-4 max-w-md mx-auto">
      <h1 className="text-2xl font-bold mb-4">AI Voice Teacher</h1>
      <div className="space-y-4">
        <Button onClick={handleStartListening} disabled={listening || isProcessing} color="blue">
          Start Listening
        </Button>
        <Button onClick={handleStopListening} disabled={!listening || isProcessing} color="red">
          Stop Listening
        </Button>
        <Button onClick={stopSpeaking} disabled={listening || isProcessing} color="yellow">
          Stop Speaking
        </Button>
        <Button onClick={resetTranscript} disabled={listening || isProcessing} color="gray">
          Reset
        </Button>
        <select 
          value={selectedVoice ? selectedVoice.name : ''} 
          onChange={(e) => setSelectedVoice(voices.find(v => v.name === e.target.value))}
          className="block w-full mt-2 p-2 border rounded"
        >
          {voices.map(voice => (
            <option key={voice.name} value={voice.name}>
              {voice.name} ({voice.lang})
            </option>
          ))}
        </select>
      </div>
      <p className="mt-4">Status: {listening ? "Listening" : isProcessing ? "Processing" : waitingForUserResponse ? "Waiting for your response" : awaitingResponse ? "Awaiting Response" : "Ready"}</p>
      <p className="mt-2">Current topic: {currentTopic || "Not set"}</p>
      <p className="mt-2">Last transcript: {transcript}</p>
    </div>
  );
};

export default AIVoiceTeacher;