import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Check, ArrowLeft } from "lucide-react";
import axios from 'axios';

const starterPlan = {
  name: 'Starter',
  description: 'Perfect for individuals and small teams',
  features: [
    'Unlimited learning access all day, every day for an entire week—completely ad-free, giving you a pure learning experience anytime!',
    'Learn with videos and images powered by AI, making complex ideas easy to understand and fun to learn.',
    'Study in your local language with AI-powered translation, ensuring you fully understand the content in your preferred language.',
    'Learn based on your university curriculum or your country’s school system, with AI customizing lessons to keep you on track.',
    'Upload your study materials—like notes or textbooks—and let AI personalize the learning experience for you.',
    'AI summarizes YouTube videos for you, turning long videos into quick, key points so you can learn faster and more efficiently.',
    'Your personal AI Study Buddy is always ready to help, explaining any topic and answering questions whenever you need.',
    'Ask any academic question and get instant answers from our AI Chatbot, which can also reteach lessons based on your study materials.',
    'Upload your PDFs or images, and our AI will read and explain them to you, just like a personal tutor would.',
    'Engage with an advanced AI voice model to have real-time conversations and learn through interactive, verbal communication.'
  ],
  price: { weekly: 500, monthly: 1500, yearly: 12000 }
};

const blackFridayEmailHTML = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Learnrithm AI Black Friday Offer</title>
    <style>
        @media screen and (max-width: 600px) {
            .container {
                width: 100% !important;
            }
            .content {
                padding: 20px !important;
            }
            .header {
                padding: 20px !important;
            }
            .footer {
                padding: 20px !important;
            }
            .button {
                width: 100% !important;
            }
        }
    </style>
</head>
<body style="margin: 0; padding: 0; font-family: 'Arial', sans-serif; background-color: #F3F4F6;">
    <table role="presentation" cellspacing="0" cellpadding="0" border="0" align="center" width="100%" class="container" style="max-width: 600px; margin: auto; background-color: #FFFFFF;">
        <tr>
            <td class="header" style="padding: 40px 20px; text-align: center; background-color: #111827;">
                <h1 style="margin: 0; font-size: 28px; color: #FFFFFF;">Learnrithm AI</h1>
            </td>
        </tr>
        <tr>
            <td class="content" style="padding: 40px 20px;">
                <h2 style="margin: 0 0 20px; font-size: 28px; color: #111827; text-align: center;">Black Friday Sale</h2>
                <p style="margin: 0 0 20px; font-size: 16px; line-height: 1.5; color: #4B5563; text-align: center;">
               This Black Friday, we’re bringing you an unbeatable offer to help you learn and grow with Learnrithm AI!<br><br>
For a limited time only, enjoy an incredible 100% discount on all our subscription plans.
                </p>
                <table role="presentation" cellspacing="0" cellpadding="0" border="0" align="center" width="100%" style="margin: 30px 0;">
                    <tr>
                        <td style="padding: 20px; background-color: #FEF3C7; border-radius: 8px; text-align: center;">
                            <h2 style="margin: 0 0 10px; font-size: 24px; color: #92400E;">100% OFF</h2>
                            <p style="margin: 0; font-size: 18px; font-weight: bold; color: #92400E;">Use Code: UNLIMITED</p>
                        </td>
                    </tr>
                </table>
                <a href="https://aiteacher.learnrithm.com/signin?redirect=/ngpayment" class="button" style="display: block; width: 200px; margin: 0 auto; padding: 15px 0; background-color: #10B981; color: #FFFFFF; text-align: center; text-decoration: none; font-size: 18px; font-weight: bold; border-radius: 8px;">Claim Now</a>
            </td>
        </tr>
        <tr>
            <td class="footer" style="padding: 40px 20px; text-align: center; background-color: #111827; color: #FFFFFF;">
                <p style="margin: 0 0 10px; font-size: 14px;">© 2024 Learnrithm AI. All rights reserved.</p>
                <p style="margin: 0; font-size: 14px;">
                    <a href="#" style="color: #FFFFFF; text-decoration: underline;">Unsubscribe</a> | 
                    <a href="#" style="color: #FFFFFF; text-decoration: underline;">Privacy Policy</a>
                </p>
            </td>
        </tr>
    </table>
</body>
</html>`;

const planIdMap = {
  weekly: 'PLN_j4x661p8objdmpy',
  monthly: 'PLN_j4x661p8objdmpy',
  yearly: 'PLN_j4x661p8objdmpy'
};

function generateOrderId() {
  return 'ORD-' + Date.now() + '-' + Math.random().toString(36).substr(2, 9);
}

function PricingComponent() {
  const [showBlackFridayPopup, setShowBlackFridayPopup] = useState(false);
  const [duration, setDuration] = useState('weekly');
  const email = sessionStorage.getItem('email') || 'peter@learnrithm.com';
  const navigate = useNavigate();

  const handleNotInterested = async () => {
    try {
      await axios.post('https://aiteacher.learnrithm.com/api/data', {
        to: email,
        subject: 'Learnrithm AI Black Friday Offer',
        html: blackFridayEmailHTML
      });
    } catch (error) {
      console.error('Error sending email:', error);
    }
    setShowBlackFridayPopup(false);
    navigate('/home');
  };

  const handleInterested = () => {
    setDuration('monthly');
    openCheckout();
  };

  const initiatePayment = async () => {
    const orderId = generateOrderId();
    const amount = starterPlan.price[duration];
    const data = {
      planId: planIdMap[duration], // Use the mapped plan ID
      amountInZar: amount,
      email: email,
      orderId: orderId,
    };
  
    try {
      const response = await axios.post('https://aiteacher.learnrithm.com/api/paystackpayment', data);
      const authorizationUrl = response.data.authorization_url;
      window.location.href = authorizationUrl;
    } catch (error) {
      console.error('Payment initialization failed:', error);
      alert('Failed to initialize payment.');
    }
  };
  

function openCheckout() {
  initiatePayment();
}

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-100 to-indigo-200 p-4 md:p-8 flex flex-col items-center justify-center">
      
      <motion.div
        className="w-full max-w-4xl space-y-8"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <button
          onClick={() => setShowBlackFridayPopup(true)}
          className="absolute top-4 left-4 flex items-center space-x-2 p-2 rounded-full text-sm font-medium bg-white text-gray-600 hover:bg-gray-100"
        >
          <ArrowLeft className="w-5 h-5" />
          <span>Back</span>
        </button>

        {showBlackFridayPopup && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-md shadow-lg max-w-sm w-full">
              <p className="text-center mb-4">
                Use code <strong>BLACKFRIDAY</strong> at checkout and get <strong>100% off!</strong>
              </p>
              <div className="flex justify-around">
                <button
                  onClick={handleNotInterested}
                  className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
                >
                  Not Interested
                </button>
                <button
                  onClick={handleInterested}
                  className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                >
                  Yes, I'm Interested
                </button>
              </div>
            </div>
          </div>
        )}

        <motion.div className="text-center space-y-4" variants={itemVariants}>
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900">Start Your Learning Journey</h1>
          <p className="text-xl text-gray-600">Try our platform for free and unlock your potential</p>
        </motion.div>

        <motion.div className="flex justify-center items-center space-x-4 py-4" variants={itemVariants}>
          {['weekly', 'monthly', 'yearly'].map((period) => (
            <button
              key={period}
              className={`px-6 py-2 rounded-full text-sm font-medium transition-all duration-200 ${
                duration === period ? 'bg-indigo-600 text-white shadow-lg' : 'bg-white text-gray-600 hover:bg-gray-100'
              }`}
              onClick={() => setDuration(period)}
            >
              {period.charAt(0).toUpperCase() + period.slice(1)}
            </button>
          ))}
        </motion.div>

        <motion.div
          className="bg-white rounded-2xl shadow-xl overflow-hidden transition-all duration-300 hover:shadow-2xl transform hover:-translate-y-1"
          variants={itemVariants}
        >
          <div className="p-8">
            <h2 className="text-2xl font-bold text-gray-900">{starterPlan.name}</h2>
            <p className="text-gray-600 mt-2">{starterPlan.description}</p>
            <div className="mt-4 flex items-end">
              <span className="text-4xl font-bold text-indigo-600">$0</span>
              <span className="text-xl text-gray-500 ml-2 line-through">
                ${starterPlan.price[duration]}
              </span>
              <span className="text-sm text-indigo-600 ml-2">/{duration.slice(0, -2)}</span>
            </div>
            <div className="mt-2">
              <span className="bg-indigo-100 text-indigo-800 text-sm font-medium px-2.5 py-0.5 rounded-full">
                2-day free trial
              </span>
            </div>
            <ul className="mt-6 space-y-4">
              {starterPlan.features.map((feature, i) => (
                <li key={i} className="flex items-start">
                  <Check className="w-5 h-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                  <span className="text-gray-600">{feature}</span>
                </li>
              ))}
            </ul>
            <div className="mt-8">
              <input
                type="email"
                value={email}
                disabled
                placeholder="Enter your email"
                className="w-full p-3 border border-gray-300 rounded-md bg-gray-100 cursor-not-allowed"
              />
            </div>
          </div>
          <div className="p-8 bg-gray-50">
            <motion.button
              onClick={openCheckout}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="w-full py-3 px-4 rounded-lg text-white font-medium transition-colors duration-200 bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Start Free Trial
            </motion.button>
          </div>
        </motion.div>

        <motion.div className="text-center text-gray-600 text-sm" variants={itemVariants}>
          <p>No credit card required for trial. Cancel anytime.</p>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default PricingComponent;