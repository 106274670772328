// SignUp.js

import React, { useEffect, useState } from "react";
import { country, name, logo, serverURL, partnerServer } from "../constants"; // Import 'country'
import LogoComponent from "../components/LogoComponent";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AiOutlineLoading } from "react-icons/ai";
import { Helmet } from "react-helmet";
import { websiteURL, quizURL } from "../constants";
const SignUp = () => {
  const storedTheme = sessionStorage.getItem("darkMode");
  const [mName, setName] = useState("");
  const [rcode, setRcode] = useState("");
  const [refCode, setRefCode] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [processing, setProcessing] = useState(false);
  const [refMail, setRefMail] = useState("");
  const [userCountry, setUserCountry] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("REFCODE");
    if (code) {
      setRcode(code);
    }
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("auth")) {
      navigate("/home");
    }
  }, [navigate]);

  const showToast = (msg) => {
    setProcessing(false);
    toast(msg, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!mName || !email || !password || !userCountry) {
      showToast("Please fill in all required fields");
      return;
    } else if (password.length < 9) {
      showToast("Password should be at least 9 characters");
      return;
    }

    const postURL = `${serverURL}/api/signup`;
    const type = "free";

    try {
      setProcessing(true);
      const response = await axios.post(postURL, {
        email,
        mName,
        password,
        type,
        plan: "hobby",
        refCode,
        refMail,
        app: "AITeacher",
        country: userCountry, // Include 'country' in the payload
      });
      if (response.data.success) {
        showToast(response.data.message);
        sessionStorage.setItem("email", email);
        sessionStorage.setItem("mName", mName);
        sessionStorage.setItem("auth", true);
        sessionStorage.setItem("uid", response.data.userId);
        sessionStorage.setItem("type", "free");
        sessionStorage.setItem("isVerified", "false");
        sessionStorage.setItem("country", userCountry); // Save country to sessionStorage
        await sendWelcomeEmail(email, mName);
        navigate("/home");
      } else {
        showToast(response.data.message);
      }
    } catch (error) {
      console.error("Signup Error:", error);
      showToast("Internal Server Error");
    }
  };

  const sendWelcomeEmail = async (mEmail, mName) => {
    try {
      const dataToSend = {
        subject: `Welcome to ${name}`,
        to: mEmail,
        html: `
          <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <style>
        body {
          font-family: 'Helvetica Neue', Arial, sans-serif;
          background-color: #f5f5f5;
          margin: 0;
          padding: 0;
        }
        .email-container {
          max-width: 600px;
          margin: 40px auto;
          padding: 20px;
          background-color: #ffffff;
          border-radius: 8px;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        }
        .email-header {
          text-align: center;
          margin-bottom: 30px;
        }
        .email-header img {
          width: 60px;
          margin-bottom: 20px;
        }
        .email-header h1 {
          font-size: 24px;
          color: #333;
          margin: 0;
        }
        .email-body {
          font-size: 16px;
          line-height: 1.6;
          color: #555;
        }
        .email-body strong {
          color: #333;
        }
        .app-links {
          margin: 20px 0;
        }
        .app-links a {
          display: inline-block;
          margin-right: 15px;
          color: #007bff;
          text-decoration: none;
        }
        .email-cta {
          text-align: center;
          margin: 30px 0;
        }
        .email-cta a {
          padding: 12px 30px;
          background-color: #007bff;
          color: #fff;
          text-decoration: none;
          border-radius: 5px;
          font-size: 16px;
        }
        .email-footer {
          margin-top: 40px;
          font-size: 14px;
          color: #999;
          text-align: center;
        }
      </style>
    </head>
    <body>
      <div class="email-container">
        <div class="email-header">
          <img src="${logo}" alt="${name} Logo">
          <h1>Welcome to Learnrithm</h1>
        </div>
        <div class="email-body">
          <p>Hi ${mName},</p>
          <p>Welcome to the <strong>Learnrithm</strong> application! By creating this account, you now have access to all our applications:</p>
          <div class="app-links">
            <a href="${websiteURL}">AI Teacher</a>
            <a href="${quizURL}">AI Quiz</a>
            <a href="${partnerServer}">Partner Program</a>
          </div>
       
          <p>Best regards,<br>The Learnrithm Ai Team</p>
        </div>
        <div class="email-footer">
          <p>&copy; ${new Date().getFullYear()} Learnrithm Ai. All rights reserved.</p>
        </div>
      </div>
    </body>
  </html>
        `,
      };

      const postURL = `${serverURL}/api/data`;
      await axios.post(postURL, dataToSend);
    } catch (error) {
      console.error("Error sending welcome email:", error);
    }
  };

  useEffect(() => {
    async function checkRefCode(rcode) {
      try {
        const res = await axios.post(`${partnerServer}/api/RefUser`, {
          data: { rcode },
          type: "check",
        });
        if (res.data.status === 200) {
          setError(undefined);
          setMessage("Referral code verified.");
          setRefMail(res.data.refMail);
          setRefCode(rcode);
        } else if (res.data.status === 404) {
          setMessage(undefined);
          setError("Referral code does not exist.");
        }
      } catch (error) {
        console.error("Error checking referral code:", error);
      }
    }

    if (rcode) {
      checkRefCode(rcode);
    }
  }, [rcode]);

  return (
    <>
      <Helmet>
        <title>Sign Up - {name}</title>
        <meta name="description" content={`Create a ${name} account.`} />
        <meta name="keywords" content={`${name}, sign up, registration, account`} />
        <meta property="og:title" content={`Sign Up - ${name}`} />
        <meta property="og:description" content={`Create a ${name} account.`} />
        <meta property="og:url" content={`${serverURL}/signup`} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={logo} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Sign Up - ${name}`} />
        <meta name="twitter:description" content={`Create a ${name} account.`} />
        <meta name="twitter:image" content={logo} />
      </Helmet>

      <div className="flex min-h-full flex-col justify-center px-6 py-12 bg-white">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm">
          <form onSubmit={handleSignup} className="space-y-6">
            {/* Logo centered within the form */}
            <div className="flex flex-col items-center">
              <LogoComponent isDarkMode={storedTheme} />
              <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-gray-900">
                Create an account
              </h2>
            </div>

            {/* Name field */}
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-900">
                Name
              </label>
              <div className="mt-2">
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={mName}
                  onChange={(e) => setName(e.target.value)}
                  required
                  className="block w-full rounded-md border-gray-300 py-1.5 text-gray-900 shadow-sm focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm"
                />
              </div>
            </div>

            {/* Email field */}
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="block w-full rounded-md border-gray-300 py-1.5 text-gray-900 shadow-sm focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm"
                />
              </div>
            </div>

            {/* Password field */}
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-900">
                Password
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="block w-full rounded-md border-gray-300 py-1.5 text-gray-900 shadow-sm focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm"
                />
              </div>
            </div>

            {/* Country field */}
            <div>
              <label htmlFor="country" className="block text-sm font-medium text-gray-900">
                Country
              </label>
              <div className="mt-2">
                <select
                  id="country"
                  name="country"
                  value={userCountry}
                  onChange={(e) => setUserCountry(e.target.value)}
                  required
                  className="block w-full rounded-md border-gray-300 py-1.5 text-gray-900 focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm"
                >
                  <option value="">Select your country</option>
                  {country.map((countryOption) => (
                    <option key={countryOption.value} value={countryOption.label}>
                      {countryOption.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Referral Code field */}
            <div>
              <label htmlFor="refCode" className="block text-sm font-medium text-gray-900">
                Referral Code (Optional)
              </label>
              <div className="mt-2">
                <input
                  id="refCode"
                  name="refCode"
                  type="text"
                  value={rcode}
                  onChange={(e) => setRcode(e.target.value)}
                  className="block w-full rounded-md border-gray-300 py-1.5 text-gray-900 shadow-sm focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm"
                />
              </div>
              {error ? (
                <p className="text-sm text-red-600 mt-1">{error}</p>
              ) : (
                message && <p className="text-sm text-green-600 mt-1">{message}</p>
              )}
            </div>

            {/* Submit button */}
            <div>
              <button
                type="submit"
                disabled={processing}
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-600 disabled:opacity-50"
              >
                {processing ? (
                  <AiOutlineLoading className="h-5 w-5 animate-spin" />
                ) : (
                  "Sign Up"
                )}
              </button>
            </div>
          </form>

          <p className="mt-6 text-center text-sm text-gray-500">
            Already have an account?{" "}
            <span
              onClick={() => navigate("/signin")}
              className="font-semibold text-indigo-600 hover:text-indigo-500 cursor-pointer"
            >
              Sign in
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default SignUp;