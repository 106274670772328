// CaptionComponent.js
import React, { useState } from 'react';

function CaptionComponent() {
  const [videoUrl, setVideoUrl] = useState('');
  const [summary, setSummary] = useState('');
  const [error, setError] = useState('');

  const getSummary = async () => {
    try {
      const response = await fetch('https://aiteacher.learnrithm.com/get-summary', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ videoUrl })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Unknown error');
      }

      const data = await response.json();
      setSummary(data.summary);
      setError('');
    } catch (err) {
      console.error('Error:', err);
      setError(`Error: ${err.message}`);
      setSummary('');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getSummary();
  };

  return (
    <div>
      <h1>YouTube Video Summary</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={videoUrl}
          onChange={(e) => setVideoUrl(e.target.value)}
          placeholder="Enter YouTube URL"
        />
        <button type="submit">Get Summary</button>
      </form>
      {summary && (
        <>
          <h2>Summary:</h2>
          <p>{summary}</p>
        </>
      )}
      {error && (
        <>
          <h2>Error:</h2>
          <p>{error}</p>
        </>
      )}
    </div>
  );
}

export default CaptionComponent;