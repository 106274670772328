import React from "react";
import AIVoiceModel from "../components/aivoicemodel"; // Adjust the path if necessary

const VoiceModel = () => {
  return (
    <div>
      <h1>Voice Model Page</h1>
      <AIVoiceModel />
    </div>
  );
};

export default VoiceModel;